<template>
    <div class="main">
        <div class="btn" @click="generateQR">生成邀请二维码</div>
        <div class="ruleBox">
            <div class="ruleTitle">活动规则</div>
            <div class="rule">1. 同一设备或同一手机号视为相同用户<br>
2. 同一设备或同一手机号只能被邀请一次<br>
3. 邀请的用户注册成功后，将得到300金币奖励<br>
4. 对于违规或作弊的行为，平台有权没收或不发放金币<br>
5. 最终解释权归平台所有</div>
        </div>
    </div>
</template>

<script>
export default {
    date() {
        return {}
    },
    methods: {
        generateQR() {
            this.$router.push({
                path: '/inviteEarnGold/qrCode',
                query: this.$route.query,
            })
        }
    },
}
</script>

<style scoped>
.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 375px;
    height: 812px;

    background: url('../../assets/inviteEarnGold/rule_bg.png');
    background-repeat: no-repeat ;
    background-position: center;
    background-size: 100% ;
}

.btn {
    margin-top: 100px;
    width: 220px;
    height: 50px;
    margin-bottom: -25px;
    z-index: 10;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 22px;

    background: url('../../assets/inviteEarnGold/button2.png');
    background-repeat: no-repeat ;
    background-position: center;
    background-size: 100% ;
}

.ruleBox {
    width: 329px;
    height: 400px;
    background: #FFFFFF;
    border-radius: 10px;
}

.ruleTitle {
    margin-top: 75px;
    margin-left: 22px;
    width: 69px;
    height: 17px;
    font-size: 17px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #FD4B0C;
    line-height: 23px;
}

.rule {
    margin-top: 22px;
    margin-left: 22px;
    word-break:break-all;
    width: 292px;
    height: 140px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FF7518;
    line-height: 32px;
}
</style>